import React, {useState} from 'react';
import styled from 'styled-components';

const Cookies = () => {
    const [contenedorCookie, cambiarContenedorCookie] = useState('none');

    const cerrarCookie = () => {
        if(contenedorCookie === ''){
            cambiarContenedorCookie('none')
        }
    }
    const aceptarCookie = () => {
        setCookie('vet', 'g2346t9423a5792', 250);
        cerrarCookie();

    }
    function setCookie(name, value, days){
        let d = new Date();
        d.setTime(d.getTime() + (days*24*60*60*1000));
    
        const expiracion = 'expires=' + d.toUTCString();
        document.cookie = `${name} = ${value}; ${expiracion}`;
    }
    
    function getCookie(cname) {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }

    if(getCookie('vet') === 'g2346t9423a5792'){
        cerrarCookie();
    } else {
      setTimeout(function(){
        cambiarContenedorCookie('')
      }, 2000);
    } 

    return (
        <CookiesContenedor className={contenedorCookie}>
            <div className="cookies">
               <div>
                    <TextoCookie><p>En cumplimiento del Real Decreto-ley 13/2012 te informo que esta web usa cookies propias y ajenas, con fines estadísticos y para que tu navegación sea más rápida.</p></TextoCookie>
                    <BotonesCookie>
                        <button type="button" className="cerrar" onClick={cerrarCookie}>Cerrar</button>
                        <button type="button" className="aceptar" onClick={aceptarCookie}>Aceptar</button>
                    </BotonesCookie>
                </div> 
            </div>
            
        </CookiesContenedor>
    );
}

const CookiesContenedor = styled.div`
  height: 70%;
  width: 90%;
  position: absolute;
  top: 20%;
  left: 5%;
  border-radius: 5rem;
  background-color: rgba(0, 0, 0, .8);
  div.cookies{
    height: 100%;
    display: grid;
    place-items: center;
  }
`;
const TextoCookie = styled.div`
    p{
        padding: 1rem;
        color: #ffffff;
    }
`;
const BotonesCookie = styled.div`
    color: #ffffff;
    display: flex;
    justify-content: space-around;
    button{
        border: none;
        width: 12rem;
        padding: 1rem;
        border-radius: .5rem;
        text-transform: uppercase;
        font-weight: 700;
        &:nth-of-type(1){
            background-color: #F7BE81;
        }
        &:nth-of-type(2){
            background-color: #64FE2E;
        }
    }
`;
 
export default Cookies;