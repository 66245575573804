const calendario = [
    {
        id: 0,
        enero1: {dia: '1 de enero', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        enero2: {dia: '28 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        marzo19: {dia: '29 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril1: {dia: '1 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril2: {dia: '2 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        mayo3: {dia: '15 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        octubre12: {dia: '25 de julio', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        noviembre1: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // noviembre9: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre6: {dia: '9 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre8: {dia: '6 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre25: {dia: '8 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre: {dia: '25 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'}
    },
    {
        id: 1,
        enero1: {dia: '1 de enero', clase: 'trabaja', texto: 'sí trabaja'},
        enero2: {dia: '28 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        marzo19: {dia: '29 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        abril1: {dia: '1 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril2: {dia: '2 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        mayo3: {dia: '15 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        octubre12: {dia: '25 de julio', clase: 'trabaja', texto: 'sí trabaja'},
        noviembre1: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // noviembre9: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre6: {dia: '9 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre8: {dia: '6 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre25: {dia: '8 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre: {dia: '25 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'}
    },
    {
        id: 2,
        enero1: {dia: '1 de enero', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        enero2: {dia: '28 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        marzo19: {dia: '29 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril1: {dia: '1 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril2: {dia: '2 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        mayo3: {dia: '15 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        octubre12: {dia: '25 de julio', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        noviembre1: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // noviembre9: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre6: {dia: '9 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre8: {dia: '6 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre25: {dia: '8 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre: {dia: '25 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'}
    },
    {
        id: 3,
        enero1: {dia: '1 de enero', clase: 'trabaja', texto: 'sí trabaja'},
        enero2: {dia: '28 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        marzo19: {dia: '29 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        abril1: {dia: '1 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        abril2: {dia: '2 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        mayo3: {dia: '15 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        octubre12: {dia: '25 de julio', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        noviembre1: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // noviembre9: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre6: {dia: '9 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre8: {dia: '6 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre25: {dia: '8 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre: {dia: '25 de diciembre', clase: 'trabaja', texto: 'sí trabaja'}
    },
    {
        id: 4,
        enero1: {dia: '1 de enero', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        enero2: {dia: '28 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        marzo19: {dia: '29 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril1: {dia: '1 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril2: {dia: '2 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        mayo3: {dia: '15 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        octubre12: {dia: '25 de julio', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        noviembre1: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // noviembre9: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre6: {dia: '9 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre8: {dia: '6 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre25: {dia: '8 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre: {dia: '25 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'}
    },
    {
        id: 5,
        enero1: {dia: '1 de enero', clase: 'trabaja', texto: 'sí trabaja'},
        enero2: {dia: '28 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        marzo19: {dia: '29 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril1: {dia: '1 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        abril2: {dia: '2 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        mayo3: {dia: '15 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        octubre12: {dia: '25 de julio', clase: 'trabaja', texto: 'sí trabaja'},
        noviembre1: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // noviembre9: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre6: {dia: '9 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre8: {dia: '6 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre25: {dia: '8 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre: {dia: '25 de diciembre', clase: 'trabaja', texto: 'sí trabaja'}
    },
    {
        id: 6,
        enero1: {dia: '1 de enero', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        enero2: {dia: '28 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        marzo19: {dia: '29 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril1: {dia: '1 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril2: {dia: '2 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        mayo3: {dia: '15 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        octubre12: {dia: '25 de julio', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        noviembre1: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // noviembre9: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre6: {dia: '9 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre8: {dia: '6 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre25: {dia: '8 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre: {dia: '25 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'}
    },
    {
        id: 7,
        enero1: {dia: '1 de enero', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        enero2: {dia: '28 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        marzo19: {dia: '29 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        abril1: {dia: '1 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        abril2: {dia: '2 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        mayo3: {dia: '15 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        octubre12: {dia: '25 de julio', clase: 'trabaja', texto: 'sí trabaja'},
        noviembre1: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // noviembre9: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre6: {dia: '9 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre8: {dia: '6 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre25: {dia: '8 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre: {dia: '25 de diciembre', clase: 'trabaja', texto: 'sí trabaja'}
    },
    {
        id: 8,
        enero1: {dia: '1 de enero', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        enero2: {dia: '28 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        marzo19: {dia: '29 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril1: {dia: '1 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        abril2: {dia: '2 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        mayo3: {dia: '15 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        octubre12: {dia: '25 de julio', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        noviembre1: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // noviembre9: {dia: '1 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre6: {dia: '9 de noviembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre8: {dia: '6 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre25: {dia: '8 de diciembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre: {dia: '25 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'}
    },
    {
        id: 9,
        enero1: {dia: '1 de enero', clase: 'trabaja', texto: 'sí trabaja'},
        enero2: {dia: '28 de marzo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        marzo19: {dia: '29 de marzo', clase: 'trabaja', texto: 'sí trabaja'},
        abril1: {dia: '1 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        abril2: {dia: '2 de mayo', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        mayo3: {dia: '15 de mayo', clase: 'trabaja', texto: 'sí trabaja'},
        octubre12: {dia: '25 de julio', clase: 'trabaja', texto: 'sí trabaja'},
        noviembre1: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // noviembre9: {dia: '1 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        // diciembre6: {dia: '9 de noviembre', clase: 'trabaja', texto: 'sí trabaja'},
        diciembre8: {dia: '6 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        // diciembre25: {dia: '8 de diciembre', clase: 'libra', texto: 'no trabaja de 7:00 a 20:00 horas'},
        diciembre: {dia: '25 de diciembre', clase: 'trabaja', texto: 'sí trabaja'}
    }
]

export default calendario;