import React from 'react';
import ReactToPdf from "react-to-pdf";

const ref = React.createRef();
const options = {
    orientation: 'portrait',
    unit: 'mm',
    format: 'A4'
};

const FacturaPDF = ({datos}) => {

    const calculaBI = (importe) =>{
        const numeroImporte = parseFloat(importe)
        let resultado = (10 * numeroImporte) / 11
        resultado =( Math.round(resultado * 100) ) / 100
        return resultado.toFixed(2);
    }
    const calculaIVA = importe =>{
        const numeroImporte = parseFloat(importe)
        let resultado = numeroImporte / 11;
        resultado =(Math.round(resultado * 100)) / 100
        return resultado.toFixed(2);
    }
    const importeTotal = importe =>{
        const numeroImporte = parseFloat(importe)
        return numeroImporte.toFixed(2);
    }
    let numeroFactura = ()=> Date.now();
    let fecha = new Date();
    const year = fecha.getFullYear();
    const mes = fecha.getMonth() + 1;
    const dia = fecha.getDate();

    return ( 
        <div className="overflow-auto">
            <ReactToPdf targetRef={ref} filename="FacturaTaxi.pdf" options={options} x={15} y={5} scale={1}>
                    {({ toPdf }) => <button className="btn-azul" onClick={toPdf}>Descargar Factura</button>}
            </ReactToPdf>
            <div style={{width: 600, height: 1000}} ref={ref}>
                <div>
                    <h2 className="t-center">Factura por servicio de taxi</h2>
                </div>
                <div className="taxista">
                    <p>
                        {datos[0]} NIF: {datos[1]} &nbsp;
                        Licencia: {datos[2]}<br />
                        {datos[3]}<br />
                        {datos[4]} &nbsp;
                        {datos[5]}
                    </p>
                </div>
                <div className="p-1">
                    <p>
                        Número de factura: {numeroFactura()}<br />
                        Fecha: {dia}-{mes}-{year} <br />
                        {datos[9]}<br />
                        NIF/DNI: {datos[10]}<br/>
                        {datos[11]}<br/>
                        {datos[12]}<br/>
                        {datos[13]}
                    </p>
                </div>
                <div className="p-1">
                    <p>
                        Origen: {datos[7]}<br/>
                        Destino: {datos[8]}
                    </p>
                </div>
                <div className="importe">
                    <div>
                        <p>Base Imponible</p>
                        <p>IVA 10%</p>
                        <p>Total</p>
                    </div>
                    <div>
                        <p>{calculaBI(datos[6])} &#8364;<br/></p>
                        <p>{calculaIVA(datos[6])} &#8364;<br/></p>
                        <p>{importeTotal(datos[6])} &#8364;</p>
                    </div>  
                </div>
                <div className="firma">viajandoentaxi.com</div>
            </div>
        </div>
    );
}
 
export default FacturaPDF;
