import React from 'react';
import styled from 'styled-components';
import {ReactComponent as IconoMadrid} from './../img/alcala.svg';

const Mapa = () => {
    return (
        <ContenedorIframe>
            <H2 className="t-center"><IconoMadrid/> Conoce Madrid</H2>
            <Iframe src="https://www.google.com/maps/d/embed?mid=1lFAXwkb8FO86ZFfFoRi62DHwMcA" loading="lazy" title="mapa">Cargando...</Iframe>
        </ContenedorIframe> 
    );
}

const ContenedorIframe = styled.div`
    background-color: #ffffff;
    margin-bottom: 2rem;
    box-shadow: 0 .5rem .5rem #A4A4A4;
`;
const H2 = styled.h2`
    text-align: center;
    margin-bottom: .5rem;
`;
const Iframe = styled.iframe`
    width: 100%;
    height: 450px;
    border: none;
`;
  
export default Mapa;