import React, {Suspense, lazy} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTaxi} from '@fortawesome/free-solid-svg-icons';

const Barajas = lazy(() => import('./barajas'));
const Bernabeu = lazy(() => import('./Bernabeu'));
const Museo = lazy(() => import('./Museo'));
const Templo = lazy(() => import('./Templo'));
const Arturo = lazy(() => import('./Arturo'));


const SeccionPrimera = () => {
    return (
        <ContededorTrayectos>
            <h2 className="t-center"><FontAwesomeIcon icon={faTaxi} /> Precios por trayecto</h2>
            <Trayectos>
                <Suspense fallback={<h2>Cargando...</h2>}>
                    <Barajas />
                    <Bernabeu />
                    <Museo />
                    <Templo />
                    <Arturo />
                </Suspense>
            </Trayectos>
            <p>Precios estimados sujetos a tarifas oficiales</p>
        </ContededorTrayectos>
    );
}

const ContededorTrayectos = styled.div`
    background-color: #ffffff;
    margin-bottom: 2rem;
    box-shadow: 0 .5rem .5rem #A4A4A4;
    h2{
        margin: 0;
        padding-top: 1rem;
        padding-bottom: .5rem;
    }
    p{
        margin: 0;
        padding: .5rem 1rem;
        font-size: 1.4rem;
    }
`;
const Trayectos = styled.div`
    margin: 0;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    gap: .5rem;
    justify-content: space-between;
    overflow: auto;
`;
 
export default SeccionPrimera;