const areaDePrestacionConjunta = [
    'ajalvir',
    'alcobendas',
    'alcorcon',
    'alcorcón',
    'algete',
    'arganda del rey',
    'arroyomolinos',
    'boadilla del monte',
    'brunete',
    'campo real',
    'chinchon',
    'chinchón',
    'ciempozuelos',
    'colmenar viejo',
    'coslada',
    'fuenlabrada',
    'getafe',
    'humanes',
    'las rozas',
    'leganes',
    'leganés',
    'loeches',
    'madrid',
    'majadahonda',
    'mejorada del campo',
    'moraleja de enmedio',
    'morata de tajuña',
    'mostoles',
    'móstoles',
    'navalcarnero',
    'parla',
    'paracuellos del jarama',
    'pinto',
    'pozuelo de alarcon',
    'pozuelo de alarcón',
    'quijorna',
    'rivas vaciamadrid',
    'san fernando de henares',
    'san martín de la vega',
    'san martin de la vega',
    'san sebastián de los reyes',
    'san sebastian de los reyes',
    'serranillos del valle',
    'titulcia',
    'torrejon de la calzada',
    'torrejón de la calzada',
    'torres de la alameda',
    'tres cantos',
    'valdemoro',
    'velilla de san antonio',
    'villaconejos',
    'villalbilla',
    'villanueva del pardillo',
    'villaviciosa de odón',
    'villaviciosa de odon',
    'aranjuez',
    'cobeña',
    'perales de tajuña',
    'sevilla la nueva',
    'torrejón de velasco',
    'torrejon de velasco',
    'torrelodones',
    'valdelaguna',
    'villanueva de perales',
    'daganzo',
    'guadalix de la sierra'
]

export default areaDePrestacionConjunta;