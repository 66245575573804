import React, {useState} from 'react';
import areaDePrestacionConjunta from '../datos/areaPrestacionConjunta';


const Area = () => {

    const [localidad, cambiarLocalidad] = useState('');
    const [municipio, cambiarMunicipio] = useState('');
    const [mensajeArea, cambiarMensajeArea] = useState(false);
    const [texto, cambiarTexto] = useState('');
    const [claseDiv, cambiarClaseDiv] = useState('');


    const verificaMunicipio = () => {
        
        if(areaDePrestacionConjunta.includes(municipio)){
            cambiarTexto(`${localidad} sí está en el Área de Prestación Conjunta del Taxi de Madrid`);
            cambiarClaseDiv('aviso-verde');
        }else{
            cambiarTexto(`${localidad} no está en el Área de Prestación Conjunta del Taxi de Madrid`);
            cambiarClaseDiv('aviso-rojo');
            setTimeout(() => {cambiarMensajeArea(false)}, 4000);
        }
    }

    const comprobarLocalidad =(e)=>{
        e.preventDefault();
        const inputLocalidad = e.target.value;
        cambiarLocalidad(inputLocalidad);
        cambiarMunicipio(inputLocalidad.trim().toLowerCase())
    }

    const comprobarMunicipio = e=> {
        e.preventDefault();

        if(municipio === ''){
            cambiarMensajeArea(false);
            return;
        }else{
            cambiarMensajeArea(true);
            verificaMunicipio(localidad)
        }
    }

    return (
        <div className="div-center">
            <h2 className="m-0">Área de Prestación conjunta</h2>
            <form className="my-1" onSubmit={comprobarMunicipio}>
                <label htmlFor="municipio">Introduzca el municipio para saber si pertenece al área de prestación conjunta</label><br /><br/>
                <input className="w-100" type="text" id="municipio" placeholder="municipio"
                value={localidad}
                onChange={e => comprobarLocalidad(e)}
                /><br/>
                <button className="btn my-2" type="submit">Comprobar</button>
            </form>
            {mensajeArea  ?
                <div className={claseDiv}><p>{texto}</p></div>
                :
                <div></div>
            }
        </div>
    );
}
 
export default Area;