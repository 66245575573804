import React from 'react';
import styled from 'styled-components';

const Aviso = () => {
    return (
        <ContenedorAviso>
            <h3>Refuerzos especiales durante el año</h3>
            <h4>Días laborables durante la celebración del MADO 2024</h4>
            <p>Durante la celebración del MADO 2024, el día 28 de junio y los días laborables comprendidos entre el 1 y 5 de julio de 2024, ambos incluidos, podrán prestar servicio todas las licencias entre las 20:00 horas y las 7:00 del día siguiente. En caso de celebración del evento en fechas difenrentes, se aplicará a los días laborales la celebración del mismo</p>
            <h4>Días laborables durante la celebración del MADCOOL 2024</h4>
            <p>Durante la celebración del MADCOOL 2024, los días 10 y 12 de julio de 2024, podrán prestar servicio todas las licencias entre las 20:00 horas y las 7:00 del día siguiente.En caso de celebración del evento en fechas difenrentes, se aplicará a los días laborales la celebración del mismo</p>
            <h4>Días laborables durante la celebración de Halloween 2024</h4>
            <p>Durante la celebración del Halloween 2024, el día 31 de octubre de 2023, podrán prestar servicio todas las licencias entre las 20:00 horas y las 7:00 del día siguiente.</p>
            <h4>Días laborables del periodo navideño 2024</h4>
            <p>Durante los días laborables comprendidos entre el 2 y el 24 de diciembre de 2024, ambos incluidos, y el 31 de diciembre de 2024, podrán prestar servicio todas las licencias entre las 20:00 horas y las 7:00 del día siguiente.</p>
        </ContenedorAviso>
    );
}

const ContenedorAviso = styled.div`
    background-color: #FAFAFA;
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 .5rem .5rem #D8D8D8;
`;
 
export default Aviso;