import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faCookieBite } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {

    const year = () => {
        const time = new Date();
        return time.getFullYear();
    }

    return (
        <>
        <Contenedor>
            <Nav2>
                <DivYoutuve>
                    <a href="https://www.youtube.com/channel/UCvD5Xs7vQrxd0oIK339DZjg" target='_blank' rel="noopener noreferrer"><FontAwesomeIcon icon={faYoutube} /></a>
                </DivYoutuve>
                <DivCookies>
                    <Link to="/cookies"><FontAwesomeIcon icon={faCookieBite} /></Link>
                </DivCookies>
                <DivContacto>
                    <Link to="/Contacto"><FontAwesomeIcon icon={faEnvelope} /></Link>
                </DivContacto>
            </Nav2>
            <p>Viajando en Taxi {year()}</p>
        </Contenedor>
        <DivAutor>
        <a href="https://carlos-delafuente.com/" target="_blank" rel="noreferrer">Autor: Carlos de la Fuente</a>
        </DivAutor>
        </>
    );
}

const Contenedor = styled.div`
    min-height: 8rem;
    background-color: #6E6E6E;
    p{
        margin: 0;
        font-size: 1.4rem;
        text-align: center;
    }
`;
const Nav2 = styled.nav`
    padding: 1rem;
    display: flex;
    justify-content: space-around;
`;
const DivYoutuve = styled.div`
    line-height: 0;
    a{
        text-decoration: none;
        color: #ff0000;
        font-size: 3.5rem;
        span{
            font-size: 1.4rem;
            color: #ffffff;
            width: 100%;
        }
    }
`;
const DivCookies = styled.div`
    font-size: 3.5rem;
    a{
        color: #febe75;
    }
`;
const DivContacto = styled.div`
    font-size: 3.5rem;
    a{
        color: #ffff00;
    }
`;
const DivAutor = styled.div`
    height: 6rem;
    background-color: #D8D8D8;
    display: flex;
    flex-direction: row-reverse;
    border-top: 1px solid #ffff00;
    a{
        text-decoration: none;
        margin-right: 2rem;
        margin-bottom: 3rem;
        color: #000000;
        font-size: 1.4rem;
    }
`;

export default Footer;