import React from 'react';
import styled from 'styled-components';
import normativa from './../documentos/normativa.pdf';
import Ordenanza from './../documentos/Ordenanza.pdf';
import modificacionOrdenanza from './../documentos/modificacionOrdenanza.pdf';

const Documentos = () => {

    return (
        <ContenedorReglamento>
            <h2>Reglamentos obligatorios de llevar en el taxi</h2>
            <div>
                <a href={normativa} download="Reglamento.pdf">Reglamento de los Servicios de Transporte Público</a>
            </div>
            <div>
                <a href={Ordenanza} download="Ordenanza.pdf">Ordenanza Municipal del Taxi</a>
            </div>
            <div>
                <a href={modificacionOrdenanza} download="modificacionOrdenanza.pdf">Modificación de la Ordenanza Municipal del Taxi</a>
            </div>
            <Pequeno>Puedes descargarlos en tu móvil y mostrarlos en caso de que te los pidan</Pequeno>
        </ContenedorReglamento>
    );
}

const ContenedorReglamento = styled.div`
    background-color: #FAFAFA;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 .5rem .5rem #D8D8D8;
    h2{
        margin-top: 1rem;
        margin-bottom: .5rem;
    }
    div{
        background-color: #dc3545;
        margin: 1.5rem 0;
        text-align: center;
        border-radius: 1rem;
        a{
            display: inline-block;
            padding: 1rem;
            text-decoration: none;
            color: #ffffff;
        }
    }
`; 
const Pequeno = styled.p`
    font-size: 1.4rem;
`;

export default Documentos;