import styled from "styled-components";

const ContenedorCookies = styled.div`
    padding: 1rem;
`;

const PoliticaCookies = () => {
    return (
        <ContenedorCookies>
            <h2>Política de cookies</h2>
            <h3>¿Qué es una cookie?</h3>
            <p>Una cookie es un paquete de datos que un navegador web almacena de forma automática en el ordenador de un usuario cuando este visita una página web. La cookie es enviada desde el servidor al visitante de la página web. Posteriormente, cada vez que el usuario visite esa misma página web o alguna otra del mismo dominio, la cookie será leída por el navegador web, sin ser modificada, y devuelta al servidor web.</p>
            <p>Por tanto, una cookie son sólo datos que se almacenan en el ordenador del usuario. Una cookies no es más que dos cadenas de texto que pueden estar compuestas de letras y números. Una palabra (cadena de texto) es la llave y otra palabra o cadena de texto es el valor. Cuando el navegador reconoce un dominio escoge las cookies de ese dominio (la llave y el valor), el código que programó el programador de la web reconoce esa cookie. No se reconoce al usuario, ni sus datos personales sino las dos cadenas de texto que el programador de la web instala cada vez que se visita su web. Las cookies no son software, tampoco son fragmentos de código, son simplemente cadenas de texto, es decir una cadena de letras y o números.</p>
            <h3>¿Para qué sirve una cookie?</h3>
            <p>Las cookies son utilizadas habitualmente por los servidores web para diferenciar usuarios y para actuar de diferente forma dependiendo de éstos.</p>
            <p>Los usuarios entran en nuestro sitio web y el servidor reconoce la cookie. Esto se usa con fines estadísticos para conocer el tráfico de la web.</p>
            <p>Otro uso que le damos es para mandar el aviso de cookies. De haber visitado recientemente la web no se envía el aviso si se aceptaros las cookies.</p>
            <p>Otras webs usan las cookies para reconocer al usuario y saber si está registrado y puede tener acceso a contenido reservado para usuarios registrados. Esta web no tiene ese tipo de cookies.</p>
            <h3>¿Se pueden desactivar las cookies?</h3>
            <p>Sí. Cada navegador en su panel de control tiene una sección para deshabilitar las cookies.</p>
        </ContenedorCookies>
    );
}
 
export default PoliticaCookies;