import React from 'react';
import calendario from './../datos/calendario';


const Lista = ({ultimoNumero}) => {

    return (
        <div>
            {ultimoNumero !== '' ? 
                <div>
                    <h3 className="m-1">Festivos en 2021</h3>
                    <ul className="list-style-none">
                    <li className="li">{calendario[ultimoNumero].enero1.dia}<span className={calendario[ultimoNumero].enero1.clase}>{calendario[ultimoNumero].enero1.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].enero2.dia}<span className={calendario[ultimoNumero].enero2.clase}>{calendario[ultimoNumero].enero2.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].marzo19.dia}<span className={calendario[ultimoNumero].marzo19.clase}>{calendario[ultimoNumero].marzo19.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].abril1.dia}<span className={calendario[ultimoNumero].abril1.clase}>{calendario[ultimoNumero].abril1.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].abril2.dia}<span className={calendario[ultimoNumero].abril2.clase}>{calendario[ultimoNumero].abril2.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].mayo3.dia}<span className={calendario[ultimoNumero].mayo3.clase}>{calendario[ultimoNumero].mayo3.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].octubre12.dia}<span className={calendario[ultimoNumero].octubre12.clase}>{calendario[ultimoNumero].octubre12.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].noviembre1.dia}<span className={calendario[ultimoNumero].noviembre1.clase}>{calendario[ultimoNumero].noviembre1.texto}</span></li>
                    {/* <li className="li">{calendario[ultimoNumero].noviembre9.dia}<span className={calendario[ultimoNumero].noviembre9.clase}>{calendario[ultimoNumero].noviembre9.texto}</span></li>
                    <li className="li">{calendario[ultimoNumero].diciembre6.dia}<span className={calendario[ultimoNumero].diciembre6.clase}>{calendario[ultimoNumero].diciembre6.texto}</span></li> */}
                    <li className="li">{calendario[ultimoNumero].diciembre8.dia}<span className={calendario[ultimoNumero].diciembre8.clase}>{calendario[ultimoNumero].diciembre8.texto}</span></li>
                    {/* <li className="li">{calendario[ultimoNumero].diciembre25.dia}<span className={calendario[ultimoNumero].diciembre25.clase}>{calendario[ultimoNumero].diciembre25.texto}</span></li> */}
                    <li className="li">{calendario[ultimoNumero].diciembre.dia}<span className={calendario[ultimoNumero].diciembre.clase}>{calendario[ultimoNumero].diciembre.texto}</span></li>
                    </ul>
                </div>
                : ''
            }
        </div>
    );
}
 
export default Lista;