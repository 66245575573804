import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import imagen from './../img/fitur.webp';

const ContenedorBanner = styled.div`
    width: 100%;
    max-width: 90rem;
    position: fixed;
    bottom: 3rem;
`;
const DivCierre = styled.div`
    padding-right: 1rem;
    display: flex;
    flex-direction: row-reverse;
    font-size: 3rem;
    cursor: pointer;
`;
const DivAnuncio = styled.div`
    padding: 0;
    background-color: #ffffff;
`;


const Banner = ({setBanner}) => {

    const [claseBanner, setClaseBanner] = useState('none');

   useEffect(() => {
        setTimeout(() => {setClaseBanner('')}, 6000)
    }, [])

    return (

        <ContenedorBanner className={claseBanner}>
            <DivCierre onClick={()=> setBanner(false)}><FontAwesomeIcon icon={faTimesCircle} /></DivCierre>
            <DivAnuncio>
                <a href="https://www.ifema.es/fitur/protocolos-covid-19?gclid=CjwKCAjwhYOFBhBkEiwASF3KGSALIE6IBFDPEgz_OhX_63P1ku_8yiubtgTLW0mGfDC_CepsrDHkPBoCExwQAvD_BwE&gclsrc=aw.ds" target="_blank" rel="noreferrer">
                  <img src={imagen} alt="viajando en taxi fitur 20211" />  
                </a>
            </DivAnuncio>
        </ContenedorBanner>
    );
}
 
export default Banner;

// {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9331273041913557"
//      crossOrigin="anonymous"></script> */}