import React, {useState} from 'react';
import Festivos from './Festivos';
import Api from './Api';
import {Helmet} from "react-helmet";
import Documentos from './Documentos';
import Aviso from './Aviso';
import Area from './Area';
import Libro from './Libro';
import PropTypes from 'prop-types';

const Libranza = () => {
    const [licencia, cambiarLicencia] = useState([]);

    return ( 
        <>
        <Helmet>
            <title>Taxistas</title>
        </Helmet>
        <div className="p-1">
            <h1 className="m-1"> Sección taxistas</h1>
            <Festivos licencia={licencia} cambiarLicencia={cambiarLicencia} /> 
            <Area />
            <Documentos />
            <Libro />
            <Aviso />
            <Api /> 
        </div>
        </>
     );
}

Festivos.propTypes = {
    licencia: PropTypes.array.isRequired,
    cambiarLicencia: PropTypes.func.isRequired
}
 
export default Libranza
