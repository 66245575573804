import React, {useState} from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faNewspaper, faTaxi } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import {NavLink, Switch, Route} from 'react-router-dom';
import Inicio from './Componentes/Inicio';
import Libranza from './Componentes/Libranza';
import Factura from './Componentes/Factura';
import Contacto from './Componentes/Contacto';
import PoliticaCookies from './Componentes/PoliticaCookies';
import Error404 from './Componentes/Error404';
import Header from './Componentes/Header';
import Cookies from './Componentes/Cookies';
import Footer from './Componentes/Footer';
import Banner from './Componentes/Banner';
import PropTypes from 'prop-types';

function App() {

  const [banner, setBanner] = useState(false);

  return (
    <Contenedor>
      <Header />
      <Menu>
        <NavLink to="/" exact={true}><FontAwesomeIcon icon={faHome} /> <span className="f-normal">Inicio</span></NavLink>
        <NavLink to="/Factura"><FontAwesomeIcon icon={faNewspaper} /> <span className="f-normal">Factura</span></NavLink>
        <NavLink to="/Taxistas"><FontAwesomeIcon icon={faTaxi} /> <span className="f-normal">Taxistas</span></NavLink>
      </Menu>
      <Main>
        <Switch>
          <Route path="/" exact={true} component={Inicio}/>
          <Route path="/index.html" component={Inicio} />
					<Route path="/factura" component={Factura}/>
          <Route path="/factura.html" component={Factura}/>
					<Route path="/taxistas" component={Libranza} />
          <Route path="/taxistas.html" component={Libranza} />
          <Route path="/contacto" component={Contacto} />
          <Route path="/cookies" component={PoliticaCookies} />
					<Route component={Error404}/>
        </Switch>
      </Main>
      <Footer />
      {banner && <Banner setBanner={setBanner} />}
      <Cookies />
    </Contenedor>
  );
}

const Contenedor = styled.div`
  max-width: 90rem;
  margin: 0 auto;
`;

const Main = styled.main`
  background-color: #ffffff;
`;
const Menu = styled.nav`
  width: 100%;
  max-width: 90rem;
  height: 40px;
  background-color: #dc3545;
  position: fixed;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-around;
  align-items: center;
  z-index: 100;
  a {
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    color: #ffffff;
  }
  a.active{
    color: #0080FF;
  }
  span.f-normal{
    font-size: 16px;
  }
`;

Banner.propTypes = {
  setBanner: PropTypes.func
}

export default App;