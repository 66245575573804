import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
import icon from './img/ico.png';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  // <React.StrictMode>
    <>
    <Helmet>
      <link rel="shortcut icon" href={icon} type="image/x-icon" />
    </Helmet>
    <BrowserRouter>
      <App />
    </BrowserRouter>
    </>,
  // </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();