import React from 'react';
import styled from 'styled-components';
import SeccionPrimera from './SeccionPrimera';
import {Helmet} from "react-helmet";
import SeccionSegunda from './SeccionSegunda';
import Mapa from './Mapa';


const Inicio = () => {
    return ( 
        <>
        <Helmet>
            <title>Viajando en Taxi</title>
        </Helmet>
        <ContenedorInicio>
            <Mapa />
            <SeccionPrimera />
            <SeccionSegunda />
        </ContenedorInicio>
         </>    
     );
}

const ContenedorInicio = styled.div`
    min-height: 60rem;
    background-color: #FAFAFA;
    padding: 0 0 2rem 0;
`;

 
export default Inicio;