import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import FacturaPDF from './Facturapdf';
import {Helmet} from "react-helmet";
import PropTypes from 'prop-types';

const Factura = () => {
    //Obtener valores de localStorage
    const localGuardado = localStorage.getItem('taxista') ? 
    JSON.parse(localStorage.getItem('taxista'))
    : [];

    const [local, cambiarLocal] = useState(localGuardado);

    const [nomTaxista, cambiarNomTaxista] = useState(localGuardado !== [] ? localGuardado[0] : '');
    const [nifTaxista, cambiarNifTaxista] = useState(localGuardado !== [] ? localGuardado[1] : '');
    const [licencia, cambiarLicencia] = useState(localGuardado !== [] ? localGuardado[2] : '');
    const [direccionTaxista, cambiarDireccionTaxista] = useState(localGuardado !== [] ? localGuardado[3] : '');
    const [localidadTaxista, cambiarLocalidadTaxista] = useState(localGuardado !== [] ? localGuardado[4] : '');
    const [cpTaxista, cambiarCpTaxista] = useState(localGuardado !== [] ? localGuardado[5] : '');
    const [origen, cambiarOrigen] = useState('');
    const [destino, cambiarDestino] = useState('');
    const [importe, cambiarImporte] = useState('');
    const [nomCliente, cambiarNomCliente] = useState('');
    const [nifCliente, cambiarNifCliente] = useState('');
    const [direccionCliente, cambiarDireccionCliente] = useState('');
    const [localidadCliente, cambiarLocalidadCliente] = useState('');
    const [cpCliente, cambiarCpCliente] = useState('');

    const [datos, cambiarDatos] = useState([]);

    //Crear localStorage
    useEffect(() => {
        localStorage.setItem('taxista', JSON.stringify(local));
    }, [local]);

    //Sube arriba de la página al hacer submit
    const subir = () => {
        window.scroll({
            top: 0,
            left: 0,
        });
    }

    //Cambia HTML. Muestra formulario o factura segun clases
    const cambiarHtml = () => {
        const formulario = document.querySelector('form');
        const factura = document.querySelector('.factura-pdf');
        const btnEditar = document.querySelector('.btn-editar');
        const pCampos = document.querySelector('.p-campos');
        const hTitulo = document.querySelector('.h-titulo');

        if(formulario.classList.contains('block')){
            formulario.classList.remove('block');
            formulario.classList.add('none');
            factura.classList.remove('none');
            factura.classList.add('block');
            btnEditar.classList.remove('none');
            btnEditar.classList.add('block');
            pCampos.classList.remove('block');
            pCampos.classList.add('none');
            hTitulo.classList.remove('block');
            hTitulo.classList.add('none');
        }else if(formulario.classList.contains('none')){
            formulario.classList.remove('none');
            formulario.classList.add('block');
            factura.classList.remove('block');
            factura.classList.add('none');
            btnEditar.classList.remove('block');
            btnEditar.classList.add('none');
            pCampos.classList.remove('none');
            pCampos.classList.add('block');
            hTitulo.classList.remove('none');
            hTitulo.classList.add('block');
        }
    }
    //Función que se ejecuta al hacer submit
    const handleSubmit = (e) => {
        e.preventDefault();
        //Datos que se pasan al componente que presenta la factura
        cambiarDatos([
            nomTaxista, nifTaxista, licencia, direccionTaxista, localidadTaxista, cpTaxista,
            importe, origen, destino,
            nomCliente, nifCliente, direccionCliente, localidadCliente, cpCliente
        ]);
        //Cambia eñ arreglo que se usa para generar localStorage
        cambiarLocal([nomTaxista, nifTaxista, licencia, direccionTaxista, localidadTaxista, cpTaxista]);
        subir();
        cambiarHtml();
    }

    return ( 
        <>
        <Helmet>
            <title>Factura</title>
        </Helmet>
        <div className="px-1 pb-5 mw-90">
            <h1 className="h-titulo block">Generar factura</h1>
            <p className="p-campos block">Los campos con * son obligatorios</p>
            <Editar>
                <button type="button" className="btn btn-editar none" onClick={cambiarHtml}>Editar factura</button>
            </Editar>
            <form className="block l-height" onSubmit={handleSubmit}>
                <label htmlFor="name">*Nombre del taxista </label>
                <Input className="w-100 m-1" type="text" id="name" placeholder="Nombre y apellidos" required 
                    value={nomTaxista}
                    onChange={e => {cambiarNomTaxista(e.target.value)}}
                />
                <label htmlFor="dni">*NIF del taxista</label>
                <Input className="w-100" type="text" id="dni" placeholder="NIF" required 
                    value={nifTaxista}
                    onChange={e => {cambiarNifTaxista(e.target.value)}}
                />
                <label htmlFor="licencia">Licencia</label>
                <Input className="w-100" type="number" min="0" max="15900" step="1" id="licencia" placeholder="Licencia" 
                    value={licencia}
                    onChange={e => cambiarLicencia(e.target.value)}
                />
                <label htmlFor="direccion">*Dirección del taxista</label>
                <Input className="w-100" type="text" id="direccion" placeholder="nombre de la calle y número" required 
                    value={direccionTaxista}
                    onChange={e => {cambiarDireccionTaxista(e.target.value)}}
                />
                <label htmlFor="localidad">*Localidad del taxista</label>
                <Input className="w-100" type="text" id="localidad" placeholder="localidad" required 
                    value={localidadTaxista}
                    onChange={e => {cambiarLocalidadTaxista(e.target.value)}}
                />
                <label htmlFor="postal">Código postal del taxista</label>
                <Input className="w-100" type="text" id="postal" placeholder="código postal" 
                    value={cpTaxista}
                    onChange={e => {cambiarCpTaxista(e.target.value)}}
                />
                <label htmlFor="origen">Origen de la carrera</label>
                <Input className="w-100" type="text" id="origen" placeholder="calle..." 
                    value={origen}
                    onChange={e => cambiarOrigen(e.target.value)}
                />
                <label htmlFor="destino">Destino de la carrera</label>
                <Input className="w-100" type="text" id="destino" placeholder="calle..." 
                    value={destino}
                    onChange={e => {cambiarDestino(e.target.value)}}
                />
                <label htmlFor="importe">*Importe</label>
                <Input className="w-100" type="number" step="0.05" min="2.40" id="importe" placeholder="2.40" required 
                    value={importe}
                    onChange={e => {cambiarImporte(e.target.value)}}
                />
                <label htmlFor="nameCliente">*Nombre del cliente o razón social</label>
                <Input className="w-100" type="text" id="nameCliente" placeholder="Nombre y apellidos / nombre empresa" required 
                    value={nomCliente}
                    onChange={e => {cambiarNomCliente(e.target.value)}}
                />
                <label htmlFor="dniCliente">*NIF/CIF del cliente</label>
                <Input className="w-100" type="text" id="dniCliente" placeholder="NIF o CIF" required 
                    value={nifCliente}
                    onChange={e => {cambiarNifCliente(e.target.value)}}
                />
                <label htmlFor="direccion-cliente">Dirección del cliente</label>
                <Input className="w-100" type="text" id="direccion-cliente" placeholder="direccion completa y número" 
                    value={direccionCliente}
                    onChange={e => {cambiarDireccionCliente(e.target.value)}}
                />
                <label htmlFor="localidad-cliente">Localidad del cliente</label>
                <Input className="w-100" type="text" id="localidad-cliente" placeholder="Localidad" 
                    value={localidadCliente}
                    onChange={e => {cambiarLocalidadCliente(e.target.value)}}
                />
                <label htmlFor="postal-cliente">Código postal</label>
                <Input className="w-100" type="text" id="postal-cliente" placeholder="código postal" 
                    value={cpCliente}
                    onChange={e => {cambiarCpCliente(e.target.value)}}
                />
                <Boton aria-label="genera la factura" className="m-2 p-1" id="calcula" type="submit">Generar factura</Boton>
            </form>
            <div className="factura-pdf none">
              <FacturaPDF className="block" datos={datos} />  
            </div>
        </div>
        </>
     );
}

FacturaPDF.propTypes = {
    datos: PropTypes.array.isRequired
}
 
export default Factura;
const Input = styled.input`
    border: ${(props)=> props.value !== '' && '2px solid #64FE2E' }
`;
const Boton = styled.button`
    color: #ffffff;
    background-color: #dc3545;
    border: none;
    border-radius: .5rem;
`;
const Editar = styled.div`
    margin: 2rem 0;
`;

