import React, {useState} from 'react';
import styled from 'styled-components';
import Lista from './Lista';


const Festivos = ({licencia, cambiarLicencia}) => {
    const [inputLicencia, cambiarInputLicencia] = useState('');
    const [revistaMunicipal, cambiaRevistaMunicipal] = useState('');
    const [ultimoNumero, cambiarUltimoNumero] = useState('');
    const [descansoAgosto, cambiarDescansoAgosto] = useState(false);
    const [parImpar, cambiarParImpar] = useState('');

    //Crea lista de calendario de libranzas
    const crearHtml = (licencia) => {
        const numeroString = licencia.substring(licencia.length-1);
        const numero = Number(numeroString);
        cambiarUltimoNumero(numero);
    }

    //Comprueba licencia para revista minicipal
    const pasarRevista = (licencia) => {
        const revista = document.querySelector('.revista');
        const numLicencia = Number(licencia);

        if(numLicencia){
            revista.classList.remove('none');
            revista.classList.add('block');
        }else{
            revista.classList.remove('block');
            revista.classList.add('none');
        }

        if(numLicencia >= 1 && numLicencia <= 2000){
            cambiaRevistaMunicipal('Tiene la revista en marzo');
        }else if(numLicencia >= 2001 && numLicencia <= 4000){
            cambiaRevistaMunicipal('Tiene la revista en abril');
        }else if(numLicencia >= 4001 && numLicencia <= 6000){
            cambiaRevistaMunicipal('Tiene la revista en mayo');
        }else if(numLicencia >= 6001 && numLicencia <= 8000){
            cambiaRevistaMunicipal('Tiene la revista en junio');
        }else if(numLicencia >= 8001 && numLicencia <= 10000){
            cambiaRevistaMunicipal('Tiene la revista en julio');
        }else if(numLicencia >= 10001 && numLicencia <= 12000){
            cambiaRevistaMunicipal('Tiene la revista en septiembre');
        }else if(numLicencia >= 12001 && numLicencia <= 14000){
            cambiaRevistaMunicipal('Tiene la revista en octubre');
        }else if(numLicencia >= 14001 && numLicencia <= 15776){
            cambiaRevistaMunicipal('Tiene la revista en nobiembre');
        }else{
            cambiaRevistaMunicipal('Esa licencia no es válida');
        }

        crearHtml(licencia);
    }

    const vacaciones = inputLicencia => {
        const numero = Number(inputLicencia);
        const resultado = numero % 2;

        if(resultado === 0){
            cambiarParImpar('Vacaciones desde las 6:00 AM del 16 de agosto hasta las 6:00 AM del 1 de septiembre');
        }else {
            cambiarParImpar('Vacaciones desde las 6:00 AM del 1 de agosto hasta las 6:00 AM del 16 de agosto');
        }
        cambiarDescansoAgosto(true);
    }

    const comprobarInputLicencia = (e) => {
        cambiarInputLicencia(e.target.value)
    }

    const comprobarLicencia = (e) => {
        e.preventDefault();
        if(inputLicencia === '' || inputLicencia === 0){
            return;
        }else{
            cambiarLicencia([inputLicencia]);
        }
        
        pasarRevista(inputLicencia);

        vacaciones(inputLicencia);
    }

    return (
        <div className="div-center">
            <h2 className="m-0">Calendario de libranzas</h2>
            <form className="my-1 l-height t-center" action="" onSubmit={comprobarLicencia}>
                <label htmlFor="libranza">Licencia nº: </label>
                <input type="number" id="libranza" min="1" max="15776" step="1" placeholder="00000" 
                  value={inputLicencia}
                  onChange={e => comprobarInputLicencia(e)}  
                /><br/>
                <button className="btn my-2" type="submit">Ver días de libranza</button>
            </form> 
            <Calendario className={inputLicencia === '' ? 'none' : 'block'}>
                <div className="revista none">
                    {revistaMunicipal}
                </div>
                {descansoAgosto ?
                    <div className="revista my-1">
                        {parImpar}
                    </div>
                :
                null}
                <div>
                   {inputLicencia === 0 || inputLicencia === '' ? 
                    <div className="libra">Esa licencia no es válida</div>
                   : <Lista ultimoNumero={ultimoNumero} />
                   } 
                </div>
            </Calendario> 
        </div>
    );
}
 
export default Festivos;

const Calendario = styled.div`
    overflow: hidden;
`;