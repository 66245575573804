import React from 'react';
import styled from 'styled-components';
import logo from './../img/logo.png';
import {Link} from 'react-router-dom';


const Header = () => {
    return ( 
        <Contenedor>
            <DivImagen>
                <Link to="/">
                    <Img src={logo} alt="viajando en taxi logo" />
                </Link>
            </DivImagen>
          <H1>Viajando en taxi</H1>  
        </Contenedor>
    );
}
 
export default Header;

const Contenedor = styled.header`
  width: 100%;
  background-color: #dc3545;
  display: flex;
  align-items: center;
  align-content: center;
`;
const DivImagen = styled.div`
    width: 5rem;
`;
const Img = styled.img`
    width: 100%;
    padding-left: 1rem;
`;
const H1 = styled.h1`
    margin: 0 auto;
    padding: 1rem;
    padding-right: 5rem;
    font-size: 2.2rem;
    color: rgba(255, 255, 255, .8);
    @media(max-width: 300px){
        padding-right: 1rem;
    }
`;