import React, {useState} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp, faInfo} from '@fortawesome/free-solid-svg-icons';

const SeccionSegunda = () => {

    const [chev, setChev] = useState(faChevronDown);
    const [chev2, setChev2] = useState(faChevronDown);
    const [chev3, setChev3] = useState(faChevronDown);
    const [objeto, setObjeto] = useState('none');
    const [derechos, setDerechos] = useState('none');
    const [oficina, setOficina] = useState('none');

    const desplegar = (e) => {
        if(e.currentTarget.dataset.valor === 'objetos-perdidos'){
            if(chev === faChevronDown){
                setChev(faChevronUp);
            }else if(chev === faChevronUp){
                setChev(faChevronDown);
            }
    
            if(objeto === 'none'){
                setObjeto('');
            }else if(objeto === ''){
                setObjeto('none');
            }
        }else if(e.currentTarget.dataset.valor === 'derechos-del-viajero'){
        
            if(chev2 === faChevronDown){
                setChev2(faChevronUp);
            }else if(chev2 === faChevronUp){
                setChev2(faChevronDown);
            }
    
            if(derechos === 'none'){
                setDerechos('');
            }else if(derechos === ''){
                setDerechos('none');
            }
        }else if(e.currentTarget.dataset.valor === 'oficina-taxi'){

            if(chev3 === faChevronDown){
                setChev3(faChevronUp);
            }else if(chev3 === faChevronUp){
                setChev3(faChevronDown);
            }

            if(oficina === 'none'){
                setOficina('');
            }else if(oficina === ''){
                setOficina('none');
            }
        }
        
    }

    return (
        <>
        <Contenedor data-valor="objetos-perdidos" onClick={e => desplegar(e)}>
          <H2><FontAwesomeIcon icon={faInfo} /> Objetos perdidos <span><FontAwesomeIcon icon={chev3} /></span></H2> 
          <div className={objeto}>
              <ul>
                  <li>Dirección: <br/>Paseo del Molino, 7</li>
                  <li>Teléfonos: <br/>010 desde dentro de Madrid <br/>915 298 210 desde dentro y fuera de la ciudad de Madrid</li>
                  <li>Horario: <br/>Laborables de lunes a viernes de 8:30 a 14 horas. Cerrado los días 24 y 31 de diciembre.</li>
                  <li>Correo: <br/>objetosperdidos@madrid.es</li>
              </ul>
          </div> 
        </Contenedor>
        <Contenedor data-valor="derechos-del-viajero" onClick={e => desplegar(e)}>
        <H2><FontAwesomeIcon icon={faInfo} /> Derechos del viajero <span><FontAwesomeIcon icon={chev2} /></span></H2> 
        <div className={derechos}>
            <ul>
                <li>Obtener un tique del servicio que contenga los datos mínimos establecidos en el artículo 22 de la Ordenanza Reguladora del Taxi. En caso de avería de la impresora, tendrán derecho a obtener un recibo con los mismos datos que el tique.</li>
                <li>Efectuar el pago del importe del servicio con tarjeta de crédito y débito en los vehículos que dispongan de lectora que lo permita.</li>
                <li>El transporte del equipaje. Asimismo, tienen derecho a que el conductor recoja el equipaje, lo coloque en el maletero del vehículo y se lo entregue a la finalización del servicio.</li>
                <li>Elegir el recorrido que considere más adecuado.</li>
                <li>Recibir el servicio con vehículos que dispongan de las condiciones necesarias en cuanto a higiene y estado de conservación, tanto exterior como interior.</li>
                <li>Solicitar que se suba o baje el volumen de la radio y otros aparatos de imagen y sonido que pudieran estar instalados en el vehículo, o que se apaguen los mismos.</li>
                <li>Solicitar que se encienda la luz interior cuando el usuario tenga dificultades de visibilidad, tanto para subir o bajar del vehículo como en el momento de efectuar el pago.</li>
                <li>Transportar gratuitamente los perros guía.</li>
                <li>Ser atendidos durante la prestación del servicio con la adecuada corrección por parte del conductor.</li>
                <li>Abrir y cerrar las puertas traseras durante la prestación del servicio, siempre que el vehículo se encuentre detenido y las condiciones del tráfico lo permitan, y requerir la apertura o cierre de las ventanillas traseras y delanteras, así como, de los sistemas de climatización de los que esté provisto el vehículo, pudiendo incluso bajar del vehículo sin coste para el usuario, si al requerir la puesta en marcha del sistema de aire acondicionado o de climatización al inicio del servicio, éste no funcionara.</li>
                <li>Elegir en las paradas de taxi, salvo en aeropuertos, estaciones ferroviarias o de autobús, el taxi con el que desea recibir el servicio.</li>
                <li>Solicitar el libro de reclamaciones, en el que podrán exponer cualquier reclamación sobre la prestación del servicio.</li>
                <li>Recibir contestación a las reclamaciones que formule.</li>
            </ul>
        </div> 
      </Contenedor>
      <Contenedor data-valor="oficina-taxi" onClick={e => desplegar(e)}>
          <H2><FontAwesomeIcon icon={faInfo} /> Oficina municipal del taxi <span><FontAwesomeIcon icon={chev3} /></span></H2> 
          <div className={oficina}>
              <ul>
                  <li>Dirección: <br/>calle Albarracín, 31</li>
                  <li>Horario: <br/>de lunes a viernes de 8:30 a 14 horas</li>
                  <li>Teléfono: <br/>914 80 46 23</li>
              </ul>
          </div> 
        </Contenedor>
      </>
    );
}
 
const Contenedor = styled.div`
    margin: 1rem;
    background-color: #ffffff;
    box-shadow: 0 .5rem .5rem #A4A4A4;
    overflow: hidden;
    border-radius: 1rem;
    cursor: pointer;
    @media(min-width: 768px){
        border-radius: 0;
        margin: 1rem 0;
    }
    div{
        margin-top: 2rem;
        padding: 1rem;
        p{
            margin: 1rem;
        }
    }
`;
const H2 = styled.h2`
    margin: 0;
    padding: 1rem;
    text-align: center;
`;

export default SeccionSegunda;