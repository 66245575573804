import React from 'react';
import styled from 'styled-components';
import libro from './../documentos/LibroDeReclamaciones.pdf';

const Libro = () => {
    return (
        <ContenedorLibro>
            <h3>Descarga e imprime</h3>
            <div>
            <a href={libro} download="LibroReclamaciones.pdf">Libro de Reclamaciones</a>
            </div>
        </ContenedorLibro>
    );
}

const ContenedorLibro = styled.div`
    background-color: #FAFAFA;
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 .5rem .5rem #D8D8D8;
    h3{
        margin: 1rem 0;
    }
    div{
        background-color: #dc3545;
        margin: 1.5rem 0;
        text-align: center;
        border-radius: 1rem;
        a{
            display: inline-block;
            padding: 1rem;
            text-decoration: none;
            color: #ffffff;
        }
    }
`;
 
export default Libro;