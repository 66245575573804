import {Helmet} from "react-helmet";

const Contacto = () => {
    return (
        <>
        <Helmet>
            <title>Contacto</title>
        </Helmet>
        <iframe title="contacto" src="https://docs.google.com/forms/d/e/1FAIpQLSeY0rTvI_gmXbBGKr3z3lDk5qd7oIc51HPkmtDNl3KMJ9VwFg/viewform?embedded=true" width="100%" height="1200" frameBorder="0" marginHeight="0" marginWidth="0">Cargando…</iframe>
        </>
    );
}
 
export default Contacto;