import React from 'react';
import styled from 'styled-components';

const Error404 = () => {
    return ( 
        <ContenedorError>
            <Div>
                <p>La página que buscas ha sido eliminada o ha cambiado de ubicación</p>
                <p>Vuelve a la página de inicio <a href="https://viajandoentaxi.com">pulsando aquí</a></p>
            </Div>
        </ContenedorError>
        
     );
}

const ContenedorError = styled.div`
    height: 65vh;
    margin: 1rem;
    display: grid;
    place-items: center;
`;

const Div = styled.div`
    padding: 2rem 1rem;
    background-color: #ea868f;
    border: 2px solid #ff0000;
    p{
        font-weight: bold;
        line-height: 2;
    }
`;
 
export default Error404;