import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import YOUTUBE_API_KEY from './ApiKey';

const Api = () => {

    const [api, setApi] = useState([]);

    const iniciarApi = () => {
        const key = YOUTUBE_API_KEY;
        const idCanal = 'UCvD5Xs7vQrxd0oIK339DZjg';
        const url = `https://www.googleapis.com/youtube/v3/search?key=${key}&channelId=${idCanal}&part=snippet,id&order=date&maxResults=6`;

        fetch(url)
            .then(respuesta => respuesta.json())
            .then(resultado => setApi(resultado.items))
            .catch(error => console.log(error));
    }

    useEffect(() => {

        iniciarApi();
        
    }, [])
    
    return (
        <>
        <H3>Lo último en <span><FontAwesomeIcon icon={faYoutube} /></span>YouTube</H3>
        <ApiCard>
            {
                api.map((item, index) => (
                        <Card key={index}>
                            <div><a href={`https://youtu.be/${item.id.videoId}`} target="_blank" rel="noopener noreferrer"><Img src={item.snippet.thumbnails.high.url} alt={`viajando en taxi ${item.snippet.title}`} /></a></div>
                            <h4 className="t-bold">{item.snippet.title}</h4>
                            <p>{item.snippet.description}</p>
                        </Card>
                ))
            }
        </ApiCard>
        </>
    );
}

const ApiCard = styled.div`
    margin-bottom: 3rem;
    min-height: 50rem;
    @media(min-width: 768px){
        display: grid;
        gap: 2rem;
        grid-template-columns: 1fr 1fr 1fr;
    }
`;

const H3 = styled.h3`
    span{
        font-size: 2rem;
        color: #ff0000;
    }
`;

const Card = styled.div`
    width: 100%;
    margin: 2rem 0;
    border-bottom: 2px solid #D4D4D4;
    &:last-of-type{
        border-bottom: none;
    }
    @media(min-width: 768px){
        &:last-of-type{
            border-bottom: 2px solid #D4D4D4
        }
    }
    p, h4{
        padding: .5rem;
        margin: 0;
    }
    h4{
        font-size: 2rem;
    }
`;
const Img = styled.img`
    width: 100%;
`;
 
export default Api;